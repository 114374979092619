@import "../index.scss";

.section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  background-color: $darkened-bg;
  width: 90%;
  height: 100%;
  border-radius: 0.5rem;
  overflow-y: scroll;
}

.content {
  padding: 40px;
}

@media (max-width: $hiddenNavbarWidth) {
  .container {
    width: 100%;
    height: 100%;
  }

  .content {
    padding-top: 100px;
  }
}

.title {
  font-weight: 400;
  font-size: 44px;
  margin: 0;
  padding-bottom: 40px;
  border-bottom: $accent-light 1px solid;
}
