@import "../index.scss";
/* Background effect from https://github.com/Carla-Codes/starry-night-css-animation */

.section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: $body-quiet-color;
  background-color: black;

  h1 {
    font-size: 44px;
    font-weight: 400;
    width: 80%;
    text-align: center;
    z-index: 3;
  }

  h2 {
    position: absolute;
    margin: 0 0 1rem 1rem;
    bottom: 0;
    left: 0;
    z-index: 3;
    font-size: 22px;
  }
}

@media (max-width: $phoneWidth) {
  .section {
    h1 {
      font-size: 30px;
    }

    h2 {
      font-size: 18px;
    }
  }
}

@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}

@keyframes move-clouds-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
}

.stars,
.twinkling,
.clouds {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.stars {
  background: transparent url("../assets/stars.png") repeat top center;
  z-index: 0;
}

.twinkling {
  background: transparent url("../assets/twinkling.png") repeat top center;
  z-index: 1;
  animation: move-twink-back 200s linear infinite;
}

.clouds {
  background: transparent url("../assets/clouds.png") repeat top center;
  z-index: 2;
  opacity: 0.4;
  animation: move-clouds-back 200s linear infinite;
}
