@import "../index.scss";

html {
  height: 100%;
}

.content {
  height: 100%;
  padding-left: $nav-width;
  overflow: hidden;
}

@media (max-width: $hiddenNavbarWidth) {
  .content {
    padding-left: 0;
  }
}
