.swipe-enter {
  opacity: 0;
  transform: translateY(5px);
}

.swipe-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 100ms ease, transform 100ms ease;
}

.swipe-exit {
  opacity: 1;
  transform: translateY(0);
}

.swipe-exit-active {
  opacity: 0;
  transform: translateY(5px);
  transition: opacity 100ms ease, transform 100ms ease;
}

.navFade-enter {
  opacity: 0;
  z-index: 98;
}

.navFade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.navFade-enter-done {
  opacity: 1;
  z-index: 98;
}

.navFade-exit {
  opacity: 1;
  z-index: 98;
}

.navFade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.navFade-exit-done {
  opacity: 0;
  z-index: -99;
}

.hidden {
  display: none;
}

.long-fade-enter {
  opacity: 0;
}

.long-fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}

.long-fade-exit {
  opacity: 1;
}

.long-fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
}

.long-fade-exit-done {
  opacity: 0;
  display: none;
}

.terminal-fade-exit-active {
  opacity: 0;
  transition: opacity 2000ms ease-in-out;
}
