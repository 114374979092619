@import "../index.scss";

.content {
  display: flex;
  flex-direction: column;

  ul {
    list-style: inside;
    list-style-type: circle;

    li {
      margin-bottom: 10px;
    }
  }

  a {
    font-weight: 700;
  }
}

.header {
  display: flex;
  justify-content: flex-start;

  h2 {
    font-weight: 400;
    margin: 0.5rem 0 1rem 0;
  }
}

.thumbnail {
  height: 200px;
  margin-right: 2rem;
}

.blurb {
  max-width: 500px;
  font-size: 1rem;
  line-height: 1.5rem;
}

.demo {
  width: 100%;
  align-self: center;
}

.sectionHeader {
  border-bottom: $accent-light solid 1px;
  padding: 24px 0 24px 0;
  margin-bottom: 24px;

  h2 {
    font-weight: 400;
    font-size: 2rem;
  }

  p {
    margin-top: 20px;
  }
}

.sectionBody {
  p {
    line-height: 2rem;
    margin-bottom: 1rem;
  }
}

.footer {
  display: flex;
  padding-top: 100px;
  flex-direction: row;
  justify-content: space-between;

  a {
    z-index: 3;
    color: $link-selected;
    font-size: 20px;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    cursor: pointer;

    p {
      font-size: 1.3rem;
      margin: 0;
    }
  }
}

@media (max-width: 1000px) {
  .header {
    flex-direction: column;
  }

  .thumbnail {
    margin: 0px 0px 1.5rem 0px;
    align-self: center;
  }

  .blurb {
    max-width: none;
  }
}

@media (max-width: $phoneWidth) {
  .header {
    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 20px;
    }
  }

  .thumbnail {
    height: 150px;
  }

  .sectionHeader {
    h2 {
      font-weight: 400;
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
    }
  }

  .sectionBody {
    p {
      line-height: 1.5rem;
    }
  }

  p,
  li {
    font-size: 1rem;
  }

  .footer {
    a {
      font-size: 1rem;
      p {
        font-size: 1rem;
      }
    }
  }
}
